import React from 'react';
import ReactDOM from 'react-dom/client';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import App from './App.jsx';
import './index.css';

const clientId = '2dfdc41381f34ca0a616296f7f3a85f0';
const domain = 'https://toaster.kinde.com';
const logoutUri = 'http://localhost:3000';
const redirectUri = 'http://localhost:3000';
const dev = true;

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <KindeProvider
      clientId={clientId}
      domain={domain}
      logoutUri={logoutUri}
      redirectUri={redirectUri}
      isDangerouslyUseLocalStorage={dev}
    >
      <App />
    </KindeProvider>
  </React.StrictMode>
);
